/* eslint-disable no-param-reassign */
import { jwtPayload } from "@insightfulscience/shared-utils/jwt/jwtPayload";
import * as Sentry from "@sentry/node";
import { Integrations } from "@sentry/tracing";
import { Dedupe } from "@sentry/integrations";
import { publicConfig } from "./config";
import { PersistentStorage } from "./services/persistent-storage";

const { SENTRY_DSN, RELEASE, ENV, SENTRY_TRACING_RATE } = publicConfig;

export const getSentryEnvName = () =>
	ENV === "DEV" ? "development" : (ENV || "LOCAL").toLowerCase();

export const assignScope = context =>
	Sentry.configureScope(scope => {
		const user = jwtPayload(PersistentStorage(context).token.read());
		scope.setUser(user);

		if (context == null) return;

		const route = context?.asPath ? context.asPath.replace(/\//g, ".") : "";

		scope.setContext("query", context.query);
		scope.setExtra("route", route);
		scope.addBreadcrumb({
			category: "query-path",
			message: route,
			level: "debug",
		});
	});

export const notifySentry = (err, context) => {
	if (!SENTRY_DSN) return;
	assignScope(context);
	Sentry.captureException(err);
};

export const initSentry = () => {
	if (!SENTRY_DSN) return;

	const sentryConfig = {
		release: RELEASE,
		dsn: SENTRY_DSN,
		environment: getSentryEnvName(),
		integrations: [new Integrations.BrowserTracing(), new Dedupe()],
		tracesSampleRate: SENTRY_TRACING_RATE != null ? parseFloat(SENTRY_TRACING_RATE) : 0,
		ignoreErrors: [
			"Non-Error exception captured",
			"Non-Error promise rejection captured",
			"Load failed",
		],
	};

	Sentry.init(sentryConfig);
};
