import { Router } from "next/router";
import * as NProgress from "./nprogress";
import routes from "./routes";
import { PersistentStorage } from "./services/persistent-storage";

Router.events.on("routeChangeStart", path => {
	if (path.indexOf(routes.purchase.base) !== 0) {
		PersistentStorage().billingInfo.clear();
	}

	NProgress.start("*");
});

Router.events.on("routeChangeError", () => {
	NProgress.end("*");
});

Router.events.on("routeChangeComplete", path => {
	global.scrollTo(0, 0);
	NProgress.end("*");

	if (path !== routes.auth.logout) {
		const isAction = path.includes("action=");
		if (!isAction) {
			global.dataLayer.push({ event: "Returned Page" });
		}
	}
});
