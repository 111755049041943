import React from "react";
import "nprogress/nprogress.css";
import { LocaleProvider } from "@insightfulscience/smart-react/i18";
import "../styles/override-nprogress.css";
import AssetsBaseUrlProvider from "@src/AssetsBaseUrlProvider";
import RouterProvider from "@insightfulscience/atomic-react/utils/RouterProvider";
import "@src/next-router-events";
import { initSentry } from "@src/sentry";
import { useRouter } from "next/router";
import resources from "../locales";
import "../src/utils/midDateFormatter"; // TODO: remove this import after migration to @is/i18
import "../src/routing-utils";

initSentry();

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps, err }) => {
	const { push } = useRouter();

	return (
		<LocaleProvider resources={resources}>
			<AssetsBaseUrlProvider>
				<RouterProvider routerPush={push}>
					<Component {...pageProps} err={err} />
				</RouterProvider>
			</AssetsBaseUrlProvider>
		</LocaleProvider>
	);
};

export default App;
