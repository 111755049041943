import diContainer from "true-di";
import storage from "./storage";
import storageService from "./storageService";
import { priceItem } from "./storageService/priceItem";
import { billingInfo } from "./storageService/billingInfo";
import { cancellationSurvey } from "./storageService/cancellationSurvey";
import { token } from "./storageService/token";
import { refreshToken } from "./storageService/refreshToken";
import { trial } from "./storageService/trial";

export const PersistentStorage = globalContext =>
	diContainer({
		globalContext: () => globalContext,
		storage,
		priceItem,
		billingInfo,
		cancellationSurvey,
		trial,
		token,
		refreshToken,
		storageService,
	}).storageService;
