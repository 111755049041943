import NProgress from "nprogress";

NProgress.configure({ showSpinner: false });

const tasks = new Set();
let started = false;

const manage = () => {
	if (tasks.size > 0 && !started) {
		started = true;
		NProgress.start();
		return;
	}

	if (tasks.size === 0 && started) {
		NProgress.done();
		started = false;
	}
};

export const start = (task = 0) => {
	if (tasks.has(task)) return;
	tasks.add(task);
	manage();
};

export const end = (task = 0) => {
	if (!tasks.has(task)) return;
	tasks.delete(task);
	manage();
};
