module.exports = {
	FORM_TITLE: "Change Payment Method",
	NAME_ON_CARD: "Name On Card",
	CC_NUMBER: "Credit Card Number",
	CC_NUMBER_PLACEHOLDER: "0000 0000 0000 0000",
	EXPIRE_MONTH: "Expiration",
	EXPIRE_MONTH_PLACEHOLDER: "MM",
	EXPIRE_YEAR: "\u00A0",
	EXPIRE_YEAR_PLACEHOLDER: "YY",
	SECURITY_CODE: "Security Code",
	SECURITY_CODE_PLACEHOLDER: "CVV",
	SECURITY_CODE_DESCR:
		"The verification number is a \n3-digit number printed on the \nback of your card. It appears after and \nto the right of your card number.",
};
