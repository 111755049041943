/* globals WEBPACK_GRAPHQL_URI */
export const IS_SERVER = typeof window === "undefined";
export const IS_BROWSER = !IS_SERVER;

const isTrue = value => ["yes", "true", "1", "on", "y"].includes(value.toLowerCase());

export const publicConfig = {
	// Build Time Configuration it will be boundeled to client and server
	RECURLY_PUBLIC_KEY: process.env.RECURLY_PUBLIC_KEY,
	GRAPHQL_URI: WEBPACK_GRAPHQL_URI,
	GRAPHQL_HEALTH_URL: process.env.GRAPHQL_HEALTH_URL,
	SB_CDN_BASE_URL: process.env.SB_CDN_BASE_URL,
	SB_CDN_HOST: process.env.SB_CDN_HOST,
	AWS_S3_PUBLIC_HOST: process.env.AWS_S3_PUBLIC_HOST,
	HTTP_PROXY: process.env.HTTP_PROXY,
	HTTPS_PROXY: process.env.HTTPS_PROXY,
	BUILD_NUMBER: process.env.BUILD_NUMBER,
	STATIC_SITE_DOMAIN: process.env.STATIC_SITE_DOMAIN,
	CI: process.env.CI,
	DEBUG_MODE: process.env.DEBUG_MODE,
	NODE_ENV: process.env.NODE_ENV,
	ENV: process.env.ENV,
	GTM_ENV_NAME: process.env.GTM_ENV_NAME,
	VWO_ID: process.env.VWO_ID,
	SENTRY_DSN: process.env.SENTRY_DSN,
	RELEASE: process.env.RELEASE,
	SENTRY_TRACING_RATE: process.env.SENTRY_TRACING_RATE,
	COOKIE_NAME: process.env.COOKIE_NAME,
	VERSION_NODE: process.env.VERSION_NODE,
	VERSION_NPM: process.env.VERSION_NPM,
	GOOGLE_RECAPTCHA_SITE_KEY: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
	CASTLE_PUBLIC_KEY: process.env.CASTLE_PUBLIC_KEY,
	HUBSPOT_PORTAL_ID: process.env.HUBSPOT_PORTAL_ID,
	HUBSPOT_CONTACT_US_FORM_ID: process.env.HUBSPOT_CONTACT_US_FORM_ID,
	HUBSPOT_FREE_COURSE_LICENSE_FORM_ID: process.env.HUBSPOT_FREE_COURSE_LICENSE_FORM_ID,
	HUBSPOT_ENTERPRISE_FORM_ID: process.env.HUBSPOT_ENTERPRISE_FORM_ID,
	IS_ISUL_GROUP_ENABLED: process.env.IS_ISUL_GROUP_ENABLED,
	SITE_DOMAIN: process.env.SITE_DOMAIN,
	CTF_ENV: process.env.CTF_ENV,
	CTF_SPACE: process.env.CTF_SPACE,
	CTF_TOKEN: process.env.CTF_TOKEN,
	FF_FREE_TRIAL_DISABLED: isTrue(process.env.FF_FREE_TRIAL_DISABLED),
	HUBSPOT_LUMA_SCIENTIFIC_INTELLIGENCE_PLATFORM_FORM_ID:
		process.env.HUBSPOT_LUMA_SCIENTIFIC_INTELLIGENCE_PLATFORM_FORM_ID,
};

export const contentfulProps = {
	environment: process.env.CTF_ENV,
	space: process.env.CTF_SPACE,
	accessToken: process.env.CTF_TOKEN,
	proxy: null,
};

export const REVALIDATE_TIME =
	publicConfig.ENV === "DEV" || publicConfig.ENV === "RC" ? 60 : 24 * 60 * 60; // 24 hours
