import QS from "querystring";
import * as withConditions from "@insightfulscience/smart-react/hocs/WithConditions";

import routes from "./routes";
import { splitParams } from "./split-params";
import { buildQuery } from "./utils-smart/url-refs";

export const sanitizePath = path => {
	try {
		const urlSanitized = new URL(path, "http://localhost");

		return {
			path: urlSanitized.pathname, // we're purposely ignoring hostname
			query: Object.fromEntries(urlSanitized.searchParams),
		};
	} catch (e) {
		return { path: "/", query: {} };
	}
};

const getReturnUrl = (pathname, queryParams, path = null) =>
	// prettier-ignore
	queryParams.returnUrl ? queryParams.returnUrl :
	pathname === routes.exchange ? routes.myAccount	:
	path; /* otherwise */

const decode = path => path && decodeURIComponent(path);
const isUtmParam = ([param]) => /^utm_/i.test(param);

const isAbsoluteURL = urlString => /^([a-z]+:)?\/\//i.test(urlString);

export const toReturnUrl = fallback => ({ query }) =>
	(!isAbsoluteURL(query.returnUrl) && decode(query.returnUrl)) || //
	fallback ||
	routes.myAccount;

const getQuery = () => QS.parse(global.location.search.replace(/^\?/, ""));

export const followToReturlUrl = ({ fallback }) => {
	const resolveReturnUrl = toReturnUrl(fallback);

	return () => {
		global.location.replace(resolveReturnUrl({ query: getQuery() }));
	};
};

export const toLoginUrl = ({ returnUrl, ...params }) =>
	`${routes.auth.login}${buildQuery({ returnUrl: encodeURIComponent(returnUrl), ...params })}`;

export const toLogin = ({ asPath }) => {
	const { path, query } = sanitizePath(asPath);
	const [, restParams] = splitParams(query, isUtmParam);

	return toLoginUrl({
		returnUrl: getReturnUrl(path, restParams) ?? `${path}${buildQuery(restParams)}`,
	});
};

const search = searchString => searchString && `?${searchString}`;

export const ref = (path, query) => `${path}${search(QS.stringify(query))}`;

withConditions.mapServerSideRedirectUrl((pathOrUrl, { query }) => {
	const { path, query: sanitizedQuery } = sanitizePath(pathOrUrl);

	return ref(path, {
		...sanitizedQuery,
		...splitParams(query, isUtmParam)[0],
	});
});

export const subsRef = (path, subscriptionID) => `${path}?subscriptionId=${subscriptionID}`;

export const toSubscriptionDashboard = ({ subscriptionID }) =>
	subsRef(routes.subscription.dashboard, subscriptionID);

export const toCompleteProfilePage = trialId =>
	global.location.replace(ref(routes.completeProfile, { trialId }));

export const toFreeTrialPage = () => `${routes.freeTrial}`;
