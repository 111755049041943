/* eslint-disable global-require */

module.exports = {
	common: require("./common.json"),
	header: require("./header.json"),
	footer: require("./footer.json"),
	graphql: require("./graphql.json"),
	"how-to-buy": require("./how-to-buy-page.json"),
	"registration-form": require("./registration-form.json"),
	account: require("./account.json"),
	links: require("./links.json"),
	preferences: require("./preferences.json"),
	pricing: require("./pricing.json"),
	"pricing-biologics": require("./pricing-biologics.json"),
	product: require("./product.json"),

	"404-page": require("./404-page.json"),

	"forgot-password-form": require("./forgot-password-form.json"),
	"forgot-password-page": require("./forgot-password-page.json"),
	"forgot-password-success-page": require("./forgot-password-success-page.json"),

	"reset-password-form": require("./reset-password-form.json"),
	"reset-password-page": require("./reset-password-page.json"),
	"reset-password-success-page": require("./reset-password-success-page.json"),
	"reset-password-fail-page": require("./reset-password-fail-page.json"),

	"login-page": require("./login-page.json"),
	"auth-login-form": require("./auth-login-form.json"),

	"configure-subscription": require("./configure-subscription.json"),
	"configure-subscription-isul": require("./configure-subscription-isul.json"),

	"purchase-billing": require("./purchase-billing.json"),
	"purchase-confirm-renewal": require("./purchase-confirm-renewal"),
	"purchase-payment": require("./purchase-payment.json"),
	"purchase-activations": require("./purchase-activations.json"),
	"purchase-activations-isul": require("./purchase-activations-isul.json"),

	"billing-info-form": require("./billing-info-form.json"),
	invoice: require("./invoice.json"),
	"cart-details": require("./cart-details.json"),
	"cart-details-isul": require("./cart-details-isul.json"),
	"credit-card-form": {
		...require("./credit-card-form.json"),
		errors: require("./payment-errors.json"),
	},
	"payment-errors": require("./payment-errors.json"),
	"paypal-form": {
		...require("./paypal-form.json"),
		errors: require("./payment-errors.json"),
	},
	subscriptions: require("./subscriptions.json"),
	"subscription-billing": require("./subscription-billing.json"),
	"subscription-billing-isul": require("./subscription-billing-isul.json"),
	"subscriptions-billing-activations": require("./subscriptions-billing-activations.json"),
	"subscriptions-billing-activations-isul": require("./subscriptions-billing-activations-isul.json"),
	"subscription-cancel": require("./subscription-cancel.json"),
	"subscription-cancel-survey": require("./subscription-cancel-survey.json"),
	"subscriptions-change-payment-method": require("./subscriptions-change-payment-method"),
	"subscriptions-change-plan": require("./subscriptions-change-plan.json"),
	"subscriptions-change-plan-isul": require("./subscriptions-change-plan-isul.json"),
	"change-payment-method-form": require("./change-payment-method-form"),
	"subscription-settings": require("./subscription-settings.json"),
	"subscription-dashboard": require("./subscription-dashboard.json"),
	"subscription-activations": require("./subscription-activations.json"),
	"add-alternate-admin-form": require("./add-alternate-admin-form.json"),
	"renew-subscription": require("./renew-subscription.json"),
	"subscription-billing-info-update-form": require("./subscription-billing-info-update-form.json"),
	"free-trial-upgrade-plan": require("./free-trial-upgrade-plan.json"),

	"pay-invoice-online": require("./pay-invoice-online.json"),

	"free-trial": require("./free-trial.json"),
	"get-started-biologics": require("./get-started-biologics.json"),
	"free-trial-complete-profile": require("./free-trial-complete-profile.json"),
	"free-trial-upgrade-payment": require("./free-trial-upgrade-payment.json"),
	"privacy-policy": require("./privacy-policy.json"),
	legal: require("./legal.json"),
	contact: require("./contact.json"),
	enterprise: require("./enterprise.json"),
	"enterprise-success": require("./enterprise-success.json"),
	"contact-form": require("./contact-form.json"),
	"section-try-for-free": require("./section-try-for-free.json"),
	"features-page": require("./features-page.json"),
	"customers-page": require("./customers-page.json"),
	"home-page": require("./home-page.json"),
	"quote-form": require("./quote-form.json"),
	"free-trial-biologics-form": require("./free-trial-biologics-form.json"),
	"quote-requested": require("./quote-requested.json"),
	"quote-requested-success": require("./quote-requested-success.json"),
	"request-license-key-success": require("./request-license-key-success.json"),
	"license-requested": require("./license-requested.json"),
	"free-trial-biologics": require("./free-trial-biologics.json"),
	"free-trial-biologics-success": require("./free-trial-biologics-success.json"),
	igem: require("./igem.json"),
	"igem-success": require("./igem-success.json"),
	"subscription-devices": require("./subscription-devices.json"),
	"subscription-seats": require("./subscription-seats.json"),
	"entitlement-dashboard": require("./entitlement-dashboard.json"),
	"entitlement-devices": require("./entitlement-devices.json"),
	"standard-pages": require("./standard-pages.json"),
	guides: require("./guides.json"),
	tutorials: require("./tutorials.json"),
	plugins: require("./plugins.json"),
	releaseNotes: require("./releaseNotes.json"),
	about: require("./about.json"),
	"free-course-license": require("./free-course-license.json"),
	updates: require("./updates.json"),
	"request-license-key-form": require("./request-license-key-form.json"),
	"request-sponsorship-form": require("./request-sponsorship-form.json"),
	"contact-success": require("./contact-success.json"),
	resources: require("./resources.json"),
	series: require("./series.json"),
	"luma-scientific-intelligence-platform": require("./luma-scientific-intelligence-platform.json"),
	"luma-scientific-intelligence-platform-success": require("./luma-scientific-intelligence-platform-success.json"),
};
